import "./app.css";

import { scan } from 'react-scan';
import { isDev } from "constants/variables/index";
import * as Sentry from "@sentry/react";
import App from "./app";
import { createRoot } from "react-dom/client";
import { createBrowserHistory } from "history";

const silenceTracker =
  isDev ||
  window.location.host.includes("dev.") ||
  window.location.host.includes("staging.");

if (process.env.NODE_ENV === "production") {
  if ("serviceWorker" in navigator) {
    navigator.serviceWorker
      .register("/service-worker.js")
      .then((registration) => {
        console.log("SW registered: ", registration);
      })
      .catch((registrationError) => {
        console.log("SW registration failed: ", registrationError);
      });
  }
  const historySentry = createBrowserHistory();
  let sentryIntregrations = [
    new Sentry.BrowserTracing({
      routingInstrumentation:
        Sentry.reactRouterV5Instrumentation(historySentry),
    }),
  ];
  if (!silenceTracker) {
    sentryIntregrations.push(
      new Sentry.Replay({
        maskAllText: false,
        maskAllInputs: false,
        blockAllMedia: false,
      })
    );
  }
  Sentry.init({
    integrations: sentryIntregrations,
    replaysSessionSampleRate: 1,
    replaysOnErrorSampleRate: 1,
    release: process.env.SENTRY_RELEASE,
    tracesSampleRate: silenceTracker ? 0.1 : 0.5,
    environment: process.env.NODE_ENV,
    normalizeDepth: 3,
    dsn: process.env.SENTRY_DSN,
    sendDefaultPii: true,
  });
} else if (process.env.REACT_SCAN && typeof window !== 'undefined') {
  scan({
    enabled: true,
  });
}

const rootElement = document.getElementById("reactEntry");
const root = createRoot(rootElement);
const serverData =
  root._internalRoot &&
  root._internalRoot.containerInfo &&
  root._internalRoot.containerInfo.dataset;
root.render(<App serverData={serverData} />);
